<template>
  <Home>
      <Preloader v-if="loader"/>
    <div v-if="!isEmpty">
      <div v-if="selectedBuilding === null">
      <div>
        <button type="button" class="btn btn-info" @click="addListing">
          <span>+</span> Add Building
        </button>
      </div>
      <div class="position-relative w-100 mt-4">
        <div v-if="buildingsData"
          class="w-100 d-flex flex-wrap justify-content-between position-relative"
        >
          <div
            class="position-relative special-column"
            v-for="(item, index) in buildingsData"
            :key="index"
          >
          <div @click="selectBuilding(item)">
            <BuildingCard :building="item" style="cursor:pointer;" />
          </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <!-- <span>
            Showing {{id}} out of  items
          </span> -->
          <!-- <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination> -->
        </div>
      </div>
      </div>
      <div class="position-relative w-100 mt-2" v-if="selectedBuilding !== null">
        <h1>{{selectedBuilding.building_name}}</h1>
        <IndividualBuilding :selectBuilding="selectBuilding" :selectedBuilding="selectedBuilding"/>
      </div>
    </div>
    <div v-if="isEmpty">
      <div style="height:calc( 100vh - 5rem)" class="w-100 d-flex align-items-center justify-content-center">
        <button type="button" class="special-button btn btn-info" @click="addListing">
          <span>+</span> Add Building
        </button>
      </div>
    </div>
  </Home>
</template>
<script>
import Home from "../Home/Home.vue";
import BuildingCard from "@/new-components/building-card/BuildingCard.vue";
import IndividualBuilding from "@/new-components/individual-building/IndividualBuilding.vue";
import {getallbuilding} from "@/utils/apis.js";
import Preloader from '@/new-components/Preloader/Preloader.vue';
export default {
  data() {
    return {
      loader: true,
      buildingsData:[],
      buildings: [
        {
          name: "Building 1",
          id: 1,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 2,
          number_of_units: 2,
          total_earnings: 75000,
        },
        {
          name: "Building 2",
          id: 2,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 45,
          number_of_units: 2,
          total_earnings: 75000,
        },
        {
          name: "Building 3",
          id: 3,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 10,
          number_of_units: 2,
          total_earnings: 78900,
        },
        {
          name: "Building 4",
          id: 4,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 27,
          number_of_units: 17,
          total_earnings: 175000,
        },
        {
          name: "Jamai Villa",
          id: 5,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 2,
          number_of_units: 2,
          total_earnings: 75000,
        },
        {
          name: "Jamai Villa",
          id: 6,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 8,
          number_of_units: 2,
          total_earnings: 15000,
        },
        {
          name: "Building 7",
          id: 7,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 2,
          number_of_units: 2,
          total_earnings: 75000,
        },
        {
          name: "Building 8",
          id: 8,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 2,
          number_of_units: 29,
          total_earnings: 79000,
        },
        {
          name: "Building 9",
          id: 9,
          image:
            "https://i.pinimg.com/474x/f1/68/e1/f168e1ca875f299be577b66d6a67345d.jpg",
          number_of_tenants: 2,
          number_of_units: 29,
          total_earnings: 59000,
        },
      ],
      currentPage: 1,
      perPage: 5,
      rows: 10,
      selectedBuilding: null,
      isEmpty: false,
    };
  },
  methods: {
    async useGetallBuilding(){
      const response = await getallbuilding();
      this.loader= false;
      this.buildingsData = response.buildings
        ? this.formatbuildingsdata(response.buildings)
        : [];
      console.log(response);
      if(response.buildings.length === 0){
        this.isEmpty = true;
      }
    },
    formatbuildingsdata(data) {
    let array = [];
    data.forEach((element) => {
      array.push({
        id: element.id,
        name:element.building_name,
        number_of_units:element.no_of_units,
        number_of_tenants:element.owner,
        image:element.image_url,
        total_earnings:element.earnings.amount__sum,
      });
    });
    console.log(array);
    return array;
    },
    addListing() {
      this.$router.push("/pages/add-listings");
    },
    selectBuilding(building) {
      this.selectedBuilding = building;
    },
  },
  mounted(){
    this.useGetallBuilding();
  },
  components: {
    Home,
    BuildingCard,
    IndividualBuilding,
    Preloader
  },
  watch: {
    selectedBuilding(newVal) {
      console.log(newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
  .special-column{
    width: 32%;
  }
  .special-button {
    height:4rem;
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 768px) {
    .special-column{
      width: 100%;
    }
    
  }
</style>
