<template>
    <div class="custom-card p-4">
        <div class="d-flex justify-content-between align-items-start">
            <div class="d-flex align-items-center">
            <div>
                <img :src="building.image" alt="" class="rounded-circle" style="object-fit:cover;height:2.3rem;width:2.3rem;">
            </div>
            <div class="mx-2">
                <div style="font-weight:900;font-size:16px;">{{building.name}}</div>
                <div style="color:#74788D" class="d-flex align-items-center">
                    <i class="fas fa-user" style="font-size:10px;margin-right:5px;"></i>
                    <span style="font-size:14.4px;"> {{building.number_of_tenants}} Tenants</span>
                </div>
            </div>
        </div>
        <div class="mt-2" style="font-size:1.7rem; line-height:0;cursor:pointer;">
            ...
        </div>
        </div>
        <div class="line"></div>
        <div class="d-flex position-relative justify-content-between p-1" style="width:85%;margin-left:auto;">
            <div class="w-50">
                <div class="mb-1" style="font-size:14.4px;color:#74788D;">Units</div>
                <h6 style="font-weight:900;">{{building.number_of_units}}</h6>
            </div>
            <div class="w-50">
                                <div class="mb-1" style="font-size:14.4px;color:#74788D;">Total Earnings</div>
                <h6 style="font-weight:900;">₹{{building.total_earnings}}</h6>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['building'],
}
</script>
<style lang="scss" scoped>
    .custom-card{
        width: 100%;
        height: 170px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 1rem;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    .line{
        width: 85%;
        height: 1px;
        background-color: #E6E6E6;
        margin: 1rem 0 1rem auto;
    }
</style>