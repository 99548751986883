<template>
  <div>
    <Preloader v-if="loader"/>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <strong>{{selectedBuilding.building_name}}</strong>
      <div class="cross" @click="selectBuilding(null)">x</div>
    </div>
    <div class="custom-card">
      <div
        class="d-flex justify-content-end align-items-center"
        style="color:#4361EE;cursor:pointer;"
        @click="$router.push({ path: '/pages/edit-listing/' + selectedBuilding.id })"
      >
        edit
      </div>
      <div class="w-90 position-relative mx-auto">
        <div
          class="d-flex justify-content-between align-items-center position-relative mb-4"
          style="cursor:pointer;"
        >
          <div
            class="custom-section"
            :class="selectedTab === 0 ? 'active' : ''"
            @click="setSelectedTab(0)"
          >
            <div style="font-size:1.2rem;">
              <i class="far fa-user-circle"></i>
            </div>
            <div><strong>About</strong></div>
          </div>
          <div
            class="custom-section"
            :class="selectedTab === 1 ? 'active' : ''"
            @click="setSelectedTab(1)"
          >
            <div style="font-size:1.2rem;">
              <i class="far fa-image"></i>
            </div>
            <div><strong>Images</strong></div>
          </div>
          <div
            class="custom-section"
            :class="selectedTab === 2 ? 'active' : ''"
            @click="setSelectedTab(2)"
          >
            <div style="font-size:1.2rem;">
              <i class="fas fa-wifi"></i>
            </div>
            <div><strong>Amenities</strong></div>
          </div>
        </div>
        <div v-if="selectedTab === 0">
          <div class="position-relative mb-4">
            <label for=""><strong>Name</strong></label>
            <input
              type="text"
              class="form-control w-40"
              :value="buildingDetails.name"
              disabled
              style="background-color:white;"
            />
          </div>
          <div class="position-relative mb-4">
            <label for=""><strong>Phone</strong></label>
            <input
              type="text"
              class="form-control w-40"
              :value="buildingDetails.phone"
              disabled
              style="background-color:white;"
            />
          </div>
          <div class="position-relative mb-4">
            <label for=""><strong>Address</strong></label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="4"
              class="w-100 form-control bg-white"
              disabled
              v-model="buildingDetails.address"
            >
                    
                </textarea
            >
          </div>
          <div class="w-100 d-flex justify-content-between mb-4">
            <div class="position-relative w-40">
              <label for=""><strong>Country</strong></label>
              <input
                type="text"
                class="form-control w-100"
                :value="buildingDetails.country"
                disabled
                style="background-color:white;"
              />
            </div>
            <div class="position-relative w-40">
              <label for=""><strong>City</strong></label>
              <input
                type="text"
                class="form-control w-100"
                :value="buildingDetails.city"
                disabled
                style="background-color:white;"
              />
            </div>
          </div>
          <div class="position-relative mb-4">
            <label for=""><strong>Zip/Postal Code</strong></label>
            <input
              type="text"
              class="form-control w-40"
              :value="buildingDetails.zip"
              disabled
              style="background-color:white;"
            />
          </div>
        </div>
        <div v-if="selectedTab === 1">
          <div class="d-flex w-100 justify-content-start flex-wrap">
            <div
              class="w-24 position-relative"
              style="margin-right:10px;"
              v-for="(item, index) in buildingDetails.images"
              :key="index"
            >
              <BuildingViewImage :building="item" />
            </div>
          </div>
        </div>
        <div v-if="selectedTab === 2">
          <div class="position-relative mb-4">
            <label for=""><strong>Number of Units</strong></label>
            <input
              type="text"
              class="form-control w-40"
              :value="buildingDetails.number_of_units"
              disabled
              style="background-color:white;"
            />
          </div>
          <div>
              <div><strong>Amenities</strong></div>
              <div>
                  <div v-for="(item,index) in buildingDetails.amenities" :key="index" style="font-size:18px;">
                      {{item.amenity_name}}
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Preloader from "@/new-components/Preloader/Preloader.vue";
import BuildingViewImage from "@/new-components/building-view-image/BuildingViewImage.vue";
import {getCurrentBuilding} from "@/utils/apis.js";
export default {
  data() {
    return {
      loader: false,
      selectedTab: 0,
      buildingDetails: {
        name: "",
        phone: "",
        address: "",
        country: "",
        city: "",
        zip: "",
        number_of_units:"",
        images: [
          {
            id: 1,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building A",
          },
          {
            id: 2,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building B",
          },
          {
            id: 3,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building C",
          },
          {
            id: 4,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building D",
          },
          {
            id: 5,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building E",
          },
          {
            id: 6,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building F",
          },
          {
            id: 7,
            image:
              "https://images.adsttc.com/media/images/5da5/56f8/3312/fd3f/6a00/0052/large_jpg/1-O_Mbuildings_PhotoByBruceDamonte.jpg?1571116781",
            caption: "Building G",
          },
        ],
        amenities:[
        ]
      },
    };
  },
  methods: {
    setSelectedTab(index) {
      this.selectedTab = index;
    },
    async useGetBuildingById() {
      console.log("useGetBuildingById", this.selectedBuilding);
      const res = await getCurrentBuilding(this.selectedBuilding.id);
      console.log("::JHH", res.data)
      // console.log("::JHH", this.selectBuilding)
      this.buildingDetails.address = res.data.building.address;
      this.buildingDetails.name = res.data.building.building_name;
      this.buildingDetails.phone = res.data.building.phone_no;
      this.buildingDetails.city = res.data.building.city;
      this.buildingDetails.country = res.data.building.country;
      this.buildingDetails.zip = res.data.building.zipcode;
      this.buildingDetails.number_of_units = res.data.building.no_of_units;
      this.buildingDetails.images = res.data.building.images;
      this.buildingDetails.amenities = res.data.building.amenities;

    }
  },
  props: ["selectedBuilding", "selectBuilding"],
  mounted() {
    this.useGetBuildingById(this.selectBuilding.id);
  },  
  components: {
    BuildingViewImage,
    Preloader
  },
};
</script>
<style lang="scss" scoped>
.cross {
  cursor: pointer;
  height: 1.4rem;
  width: 1.4rem;
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-card {
  width: 100%;
  height: 80vh;
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem;
  overflow: auto;
}
.custom-section {
  width: 33.33%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 3px solid #f6f6f6;
  color: #495057;
  &.active {
    color: #5b73e8;
    border-bottom: 3px solid #5b73e8;
  }
}
.w-90 {
  width: 90%;
}
.w-40 {
  width: 40%;
}
.w-24 {
  width: 24%;
}
</style>
