<template>
    <div class="w-100">
        <div class="custom-card-item">
            <div class="h-80">
                <img
                    class="w-100"
                    :src="building.image_url"
                    style="height:100%;object-fit:cover;"
                />
            </div>
            <div class="h-20">
                <strong style="color:#4361EE;">{{building.caption}}</strong>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["building"]
}
</script>
<style lang="scss" scoped>
    .custom-card {
        background-color: white;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        height: 15rem;
        box-shadow: 0px 3px 5px 0px rgba(1, 1, 1, 0.1);
        position: relative;
        overflow: hidden;
    }
    .h-80{
        height:80%;
    }
    .h-20{
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>